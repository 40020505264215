const personInput = document.querySelector(".input__field-person");
const billInput = document.querySelector(".input__field-bill");
const tipInput = document.querySelector(".input__field-tip");
const error = document.querySelector(".input__error");
const buttons = document.querySelectorAll(".tip__button");
const resetButton = document.querySelector(".results__button");
const r = document.querySelector(":root");
const totalElement = document.querySelector(".total");
const tipPerPerson = document.querySelector(".tip-person");
let selectedButton;
let reset;
let max;

const formatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

//add event listener to each button and remove the prior selected button before highlighting new one
buttons.forEach((button) => {
  button.addEventListener("click", (e) => {
    removeCustomValue();
    removeSelectedButton();
    enableResetButton();
    e.target.classList.add("tip__selected");
    selectedButton = document.querySelector(".tip__selected");
    calculateTip();
  });
});

// on input use a timeout so user has enough time to change number before it limit to 2 decimal places
billInput.addEventListener("input", (e) => {
  clearTimeout(reset);
  enableResetButton();
  reset = setTimeout(() => {
    if (e.target.value === "") {
      e.target.value = "";
    } else {
      e.target.value = formatter.format(e.target.value);
      calculateTip();
    }
    validInput(billInput);
    toggleError(e, billInput);
  }, 1000);
});

tipInput.addEventListener("input", (e) => {
  checkTipInputZero(e);
  removeSelectedButton();
  enableResetButton();
  calculateTip();
  validInput(tipInput);
});

personInput.addEventListener("keydown", (e) => {
  if (e.key === ".") {
    e.preventDefault();
  }
});

personInput.addEventListener("input", (e) => {
  e.target.value = e.target.value.replace(/[^0-9]*/g, "");
  if (e.target.value.length < 2) {
    max = false;
  }
  maxLength(e);
  toggleError(e, personInput);
  enableResetButton();
  validInput(personInput);
  if (!max) {
    calculateTip();
  }

  if (e.target.value.length === 2) {
    max = true;
  }
});

billInput.addEventListener("keydown", (e) => {
  if (e.key === "e") {
    e.preventDefault();
  }
});

resetButton.addEventListener("click", resetValues);

// uses the inputs maxlength prop to set maxlength on number inputs
function maxLength(e) {
  if (e.target.value.length > e.target.maxLength) {
    e.target.value = e.target.value.slice(0, e.target.maxLength);
  }
}

function enableResetButton() {
  resetButton.disabled = false;
  resetButton.style.setProperty(
    "background",
    getComputedStyle(r).getPropertyValue("--strong-cyan-2")
  );
}

function removeCustomValue() {
  tipInput.value = "";
}

function validInput(input) {
  if (input.value.length > 0 && +input.value !== 0) {
    input.style.border = "2px solid hsla(172, 67%, 45%, 1)";
  } else {
    input.style.border = "none";
  }
}

function resetValues() {
  let errorInputOutlines = document.querySelectorAll(".input__error-outline");
  let showInputs = document.querySelectorAll(".input__show");
  let inputFields = document.querySelectorAll(".input__field");
  errorInputOutlines.forEach((input) =>
    input.classList.remove("input__error-outline")
  );
  inputFields.forEach((input) => (input.style = ""));
  showInputs.forEach((input) => input.classList.remove("input__show"));
  tipInput.value = "";
  personInput.value = "";
  billInput.value = "";
  resetButton.disabled = true;
  resetButton.style = "";
  totalElement.textContent = "$0.00";
  tipPerPerson.textContent = "$0.00";
  removeSelectedButton();
}

function removeSelectedButton() {
  buttons.forEach((button) => {
    button.classList.remove("tip__selected");
  });
}

function checkTipInputZero(e) {
  console.log("hello bye");
  if (checkIfZero(e)) {
    tipInput.classList.add("input__error-outline");
  } else {
    tipInput.classList.remove("input__error-outline");
  }
}

function checkIfZero(e) {
  if (e.target.value === "") {
    return false;
  } else {
    return +e.target.value === 0 ? true : false;
  }
}

function toggleError(e, input) {
  if (checkIfZero(e)) {
    input.parentElement.previousElementSibling.children[1].classList.add(
      "input__show"
    );
    input.classList.add("input__error-outline");
  } else {
    input.parentElement.previousElementSibling.children[1].classList.remove(
      "input__show"
    );
    input.classList.remove("input__error-outline");
  }
}

function totalBill(tip) {
  return (+billInput.value * (+tip / 100)).toFixed(2);
}

function calculateTip() {
  if (
    billInput.value &&
    personInput.value &&
    (selectedButton || tipInput.value)
  ) {
    if (tipInput.value) {
      totalElement.textContent = `$${totalBill(tipInput.value)}`;
      tipPerPerson.textContent = `$${(
        totalBill(tipInput.value) / +personInput.value
      ).toFixed(2)}`;
    } else if (selectedButton) {
      let percent = selectedButton.dataset.percent;
      totalElement.textContent = `$${totalBill(percent)}`;
      tipPerPerson.textContent = `$${(
        totalBill(percent) / +personInput.value
      ).toFixed(2)}`;
    }
  }
}
